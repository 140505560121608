import config from '@/config/config';
import Axios from 'axios';

export default new class TicketsService {
    private config = config.getConfig();
    getToken() {
        return localStorage.getItem('__token');
    }

    async saveTicket(data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/tickets`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async updateTicket(id: string, data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.put(`${this.config.apiUrl}/tickets/${id}`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async deleteTicket(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.delete(`${this.config.apiUrl}/tickets/${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getAllTickets(status = 'all') {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/tickets${status !== ''  ? `?status=${status}` : ''}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getTicket(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/tickets/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
};

