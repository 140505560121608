<template>
  <LayoutDefault>
    <Loading :active="loading" />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <router-link
                :to="{ name: 'Tickets' }"
                class="custom-back m-0 ttU"
              >
                <i class="fas fa-arrow-circle-left"></i> Atrás
              </router-link>
            </div>
            <!-- /.col -->
          </div>
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Editar</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <div class="form">
            <div class="inputs">
              <div class="form-group">
                <p><b>ID Ticket:</b> {{ ticket.id }}</p>
                <p><b>Area:</b> {{ ticket.area }}</p>
                <p><b>Asunto:</b> {{ ticket.subject }}</p>
                <p>
                  <b>Solicitud:</b>
                  {{
                    (Array.isArray(JSON.parse(ticket.detail))
                      ? JSON.parse(ticket.detail)
                      : [])[0]?.comment
                  }}
                </p>
                <p><b>Creado por:</b> {{ ticket.user?.email }}</p>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1"
                  >Estatus del ticket</label
                >
                <select
                  class="form-control"
                  v-model="statusSelected"
                  id="exampleFormControlSelect1"
                >
                  <option
                    v-for="(status, index) in statusArray"
                    :value="status.toLowerCase()"
                    :key="index"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>

              <div id="chat" class="chat">
                <div
                  class="chat-message"
                  :class="el.userId === 0 ? 'admin' : 'user'"
                  v-for="(el, index) in Array.isArray(JSON.parse(ticket.detail))
                    ? JSON.parse(ticket.detail)
                    : []"
                  :key="index"
                >
                  <span>{{ el.comment }}</span>
                  <span class="date">{{ formatDate(el.created_at) }}</span>
                </div>
                <div
                  class="chat-message"
                  :class="el.userId === 0 ? 'admin' : 'user'"
                  v-for="(el, index) in newDetail"
                  :key="index"
                >
                  <span>{{ el.comment }}</span>
                  <span class="date">{{ formatDate(el.created_at) }}</span>
                </div>
              </div>
              <div class="form-group flex">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Agregar comentario"
                  name="title"
                  v-model="newComment"
                  @keyup.enter="addNewComment()"
                  autocomplete="off"
                />
                <button @click="addNewComment()" type="button">
                  <i class="fas fa-arrow-circle-up"></i>
                </button>
              </div>
              <button
                type="button"
                class="button btn btn-default btn-default-invert"
                @click="saveData()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Loading from "../components/Loading";
import config from "@/config/config";
import Toast from "../components/Toast";
import moment from "moment";
import ticketsService from "@/services/ticketsService";
export default {
  name: `TicketsEdit`,
  data() {
    return {
      ticket: { area: "", subject: "", status: "", detail: "[]", user: {} },
      config: config.getConfig(),
      loading: false,
      previewImage: "",
      toastType: "",
      toastActive: false,
      toastMessage: "",
      statusSelected: "abierto",
      statusArray: ["Abierto", "En proceso", "Cerrado"],
      newComment: "",
      newDetail: [],
    };
  },
  async beforeMount() {
    this.loading = true;
    if (this.$route.params.ticket_id) {
      await this.getTicket(this.$route.params.ticket_id);
      setTimeout(() => {
        const objDiv = document.getElementById("chat");
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    } else {
      this.$router.push("/dashboard/tickets");
    }
    this.loading = false;
  },
  methods: {
    addNewComment() {
      if (this.newComment.length > 0) {
        this.newDetail.push({
          comment: this.newComment,
          userId: 0,
          created_at: moment().toJSON(),
        });
        this.newComment = "";
        setTimeout(() => {
          const objDiv = document.getElementById("chat");
          objDiv.scrollTop = objDiv.scrollHeight;
        });
        this.saveData();
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY MM DD, h:mm:ss a");
    },
    async saveData() {
      this.loading = true;
      const data = {
        id: this.ticket.id,
        detail: JSON.stringify(this.newDetail),
        status: this.statusSelected,
      };
      const res = await ticketsService.updateTicket(data.id, data);
      if (res.error || (res.code && res.code !== 200)) {
        this.toastType = "error";
        this.toastMessage = "Fallo al guardar";
        this.toastActive = true;
        this.loading = false;
        return;
      }
      this.toastType = "success";
      this.toastMessage = "Guardado correctamente";
      this.toastActive = true;
      this.loading = false;
    },
    async getTicket(id) {
      const res = await ticketsService.getTicket(id);
      if (res.error || (res.code && res.code !== 200)) {
        this.$router.push("/dashboard/tickets");
        return;
      }
      if (res && res.response) {
        this.ticket = { ...res.response };
        this.statusSelected = this.ticket.status.toLowerCase();
      }
    }, // 1
  },
  components: {
    LayoutDefault,
    Loading,
    Toast,
  },
};
</script>
<style lang="scss">
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3em;
  button {
    position: absolute;
    right: 0.5em;
    border: none;
    font-size: 1.5em;
    outline: none;
    background: none;
    color: #ff6900;
  }
}
.chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 250px;
  overflow-y: overlay;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 2em;
  &-message {
    position: relative;
    min-width: 30%;
    padding: 0.3em 1em;
    margin-bottom: 1.2em;
    max-width: 50%;
    border-radius: 5px;
    span {
      overflow-wrap: break-word;
    }
    &.user {
      background: #f6f6f6;
      color: black;
      text-align: left;
      align-self: flex-start;
      .date {
        left: 1em;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -0.7em;
        left: 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #f6f6f6;
      }
    }
    &.admin {
      text-align: left;
      align-self: flex-end;
      background: #ff6900;
      color: white;
      .date {
        right: 1em;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -0.7em;
        right: 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid #ff6900;
      }
    }
    .date {
      position: absolute;
      bottom: -1.5em;
      font-size: 12px;
      color: black;
    }
  }
  .btn-edit {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
}
.form {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
  .inputs {
    width: 40%;
    .button {
      margin-top: 1em;
    }
  }
  .image {
    width: 40%;
    .button {
      margin-top: 1em;
    }
    img {
      width: 400px;
      height: 400px;
    }
  }
  &-group {
    width: 100%;
    position: relative;
  }
  &-control {
    padding: 1em;
    border-radius: 8px;
    resize: none;
    height: inherit;
    &:focus {
      border-radius: 8px;
    }
  }
}
</style>
